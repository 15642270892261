<template>
  <div class="user-center">
    <div
      class="header"
      :style="{
        backgroundImage: `url(${backgroundImage})`,
        height: '300px',
        width: '100%',
      }"
      @mouseenter="mouse_enter"
      @mouseleave="mouse_leave"
    >
      <div class="user_info">
        <div class="left-part">
          <img :src="user_info.avatar_url" alt="" />
        </div>
        <div class="right-part">
          <span style="font-size: 35px; margin-top: 15px">{{
            user_info.nick_name
          }}</span>
          <div class="introduction">
            <span style="font-size: 20px">{{ user_info.introduction }}</span>
          </div>
        </div>
      </div>
      <!-- <label v-if="is_show" for="file-upload" class="custom-upload-btn">
        <i class="iconfont icon-tuxiang xiangji-icon"></i>
        <span>点击更管背景图</span>
      </label>
      <input id="file-upload" type="file" @change="setBackgroundImage" /> -->
    </div>

    <div id="content-area" class="content-area">
      <div class="left-nav">
        <div class="nav-list">
          <a href="/user_center/setting" class="nav-item"
            ><i class="iconfont iconpc-shoucang"></i>个人设置
          </a>
          <a href="/user_center/integral" class="nav-item"
            ><i class="iconfont icon-pc-mine-jies"></i>积分管理
          </a>
          <a href="/user_center/timeline" class="nav-item"
            ><i class="iconfont iconpc-xihuan"></i>我的时光轴
          </a>
          <a href="/user_center/work" class="nav-item"
            ><i class="iconfont iconpc-zuopinguanli"></i>订单管理
          </a>
          <a href="/user_center/message" class="nav-item"
            ><i class="iconfont iconxitongxiaoxi"></i>最新消息
          </a>
          <a href="/user_center/pwd" class="nav-item"
            ><i class="iconfont icon-pc-mine-mima"></i>修改密码
          </a>
          <a href="/user_center/quit" class="nav-item"
            ><i class="iconfont iconquit"></i>退出登录</a
          >
        </div>
      </div>
      <div class="right-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { get_token } from "../common/common.js";
import constant from "../config/constant.js";
// import UserSetting from "./UserSetting.vue";
export default {
  name: "UserCenter",
  data() {
    return {
      user_info: {
        avatar_url: "",
        introduction: "",
        nick_name: "",
      },
      backgroundImage:
        "http://www.soutudashi.com/get_image/" +
        encodeURIComponent("bg/20771591-6dae-4970-9bc6-46db70c9b51c.webp"), // 默认背景图
      points: 100, // 示例积分
      is_show: false,
    };
  },
  methods: {
    setBackgroundImage(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.backgroundImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    mouse_leave() {
      this.is_show = false;
    },
    mouse_enter() {
      this.is_show = true;
    },
    async get_current_integral() {
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/get_integral", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          responseType: "json",
        });

        // var response = await this.$http.get(
        //   "http://www.soutudashi.com/get_integral",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //   }
        // );

        // console.log("获取赞助数据：1 ", response.data.data);
        return await response.data.data.num;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },

    async get_account_info() {
      const token = get_token("token");
      var response = await this.$http({
        method: "post",
        url: "http://www.soutudashi.com/simple_account_info", // 拼接URL和参数
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*", // 设置跨域参数
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });

      // console.log("gt_account_infoffff", response);
      if (response.data.status == 200) {
        const datas = response.data.data;
        this.user_info.nick_name = datas.nick_name;
        this.user_info.introduction = datas.introduction;
        this.user_info.avatar_url =
          constant.GET_IMAGE_ADDRESS + encodeURIComponent(datas.avatar_url);
      }
      // console.log(
      //   "用户信息wwwww： ",
      //   response,
      //   this.user_info,
      //   this.cur_avator_img
      // );
    },
  },

  // components: {
  //   UserSetting,
  // },

  async mounted() {
    this.get_account_info();
    this.points = await this.get_current_integral();
  },
};
</script>

<style scoped>
.user-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4; /* 背景色 */
}
.header {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content {
  width: 100%;
  box-sizing: border-box;
}
.points h2 {
  color: #333;
  font-size: 24px;
}
.points span {
  color: #e74c3c; /* 积分颜色 */
  font-weight: bold;
}
input[type="file"] {
  display: none; /* 隐藏文件输入框 */
}

.custom-upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 80px;
  /* background-color: green; */
  border-radius: 10px;
}

.xiangji-icon {
  font-size: 60px;
}

.user_info {
  width: calc(80%);
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 20px;
}
.user_info > .left-part > img {
  height: 148px;
  width: 148px;
  border-radius: 74px;
  border: 10px white solid;
  box-sizing: border-box;
}

.user_info > .right-part {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  gap: 15px;
  color: black;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 10px 20px;
}

.right-part > .introduction {
  width: 100%; /* 根据需要设置宽度 */
  overflow: hidden; /* 隐藏溢出的内容 */
}

.right-part > .introduction > span {
  display: -webkit-box; /* 使用弹性盒子模型 */
  -webkit-box-orient: vertical; /* 设置方向为垂直 */
  -webkit-line-clamp: 2; /* 截断两行 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 显示省略号 */
  line-height: 1.5; /* 根据需要设置行高 */
  max-height: 3em; /* 两行文本的高度，行高的两倍 */
}

.content-area {
  width: 100%;
  /* position: relative; */
  /* top: 20px; */
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.content-area > .left-nav {
  width: 223px;
}

.content-area > .left-nav > .nav-list {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 10px;
}

.content-area > .left-nav > .nav-list > .nav-item {
  height: 30px;
  text-decoration: none;
  color: #2f2f2f;
}

.content-area > .left-nav > .nav-list > .nav-item:first-child {
  margin-top: 15px;
}

.content-area > .left-nav > .nav-list > .nav-item:last-child {
  margin-bottom: 15px;
}
.content-area > .left-nav > .nav-list > .nav-item > i {
  line-height: 30px;
}

.content-area > .right-content {
  width: calc(60%);
}
</style>
